import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { seo, intro, main, vision } from '../../content/o-nas'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import ImageSection from '../../components/ImageSection'
import { s } from '../../style'
import BannerContact from '../../sections/banners/BannerContact'
import BannerHistory from '../../sections/banners/BannerHistory'
import BannerManagement from '../../sections/banners/BannerManagement'

const Onas = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/o-nas.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      main1: file(absolutePath: { regex: "/images/company/cooperation.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      main2: file(
        absolutePath: { regex: "/images/company/specialization.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      vision1: file(absolutePath: { regex: "/images/company/mission.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      vision2: file(absolutePath: { regex: "/images/company/vision.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Poznaj Sea & Air',
      en: 'Discover Sea & Air',
      ua: 'ПІЗНАЙТЕ SEA & AIR',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro data={{ ...intro, button }} image={query.image.childImageSharp} />
      <Main
        h={1}
        title={main.title[lang]}
        subtitle={main.subtitle[lang]}
        body={main.body[lang]}>
        <div css={{ [s.md]: { paddingTop: 80 } }} />
        {main.sections?.map(({ title, desc }, id) => (
          <ImageSection
            h={2}
            key={id}
            title={title[lang]}
            desc={desc[lang]}
            image={query[`main${id + 1}`].childImageSharp}
          />
        ))}
        <div css={{ [s.md]: { paddingTop: 80 } }} />
        <BannerHistory />
      </Main>
      <Main
        h={2}
        s={1}
        title={vision.title[lang]}
        subtitle={vision.subtitle[lang]}
        body={vision.body[lang]}>
        <div css={{ [s.md]: { paddingTop: 80 } }} />
        {vision.sections?.map(({ title, desc }, id) => (
          <ImageSection
            h={2}
            key={id}
            title={title[lang]}
            desc={desc[lang]}
            image={query[`vision${id + 1}`].childImageSharp}
          />
        ))}
      </Main>
      <BannerManagement />
      <BannerContact />
    </Layout>
  )
}

export default Onas
