import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { colors, s, sSection } from '../../style'
import alt from '../../content/alt'
import { useLangContext } from '../../context/lang.context'
import Label from '../../components/Label'

const content = {
  ceo: {
    firstname: 'Przemysław',
    lastname: 'Adam',
    title: {
      pl: 'Prezes Zarządu',
      en: 'President of the Board',
    },
  },
  vice: {
    firstname: 'Marcin',
    lastname: 'Błaszkowski',
    title: {
      pl: 'Wiceprezes Zarządu',
      en: 'Vice President of the Board',
    },
  },
}

const label = {
  text: {
    pl: 'Zarząd',
    en: 'Management',
  },
  color: '#0C1320',
}

const BannerManagement = () => {
  const { lang } = useLangContext()

  const { ceo, vice } = useStaticQuery(graphql`
    {
      ceo: file(absolutePath: { regex: "/images/management/ceo.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
      vice: file(absolutePath: { regex: "/images/management/vice.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1
          )
        }
      }
    }
  `)

  return (
    <section css={[sSection, sRelative]}>
      <div css={sBanner}>
        <div css={[sRelative, { height: 'max-content' }]}>
          <GatsbyImage
            image={getImage(ceo.childImageSharp)}
            alt={content.ceo.firstname + ' ' + content.ceo.lastname + alt}
            css={sImageCeo}
          />
          <div css={sText}>
            <span>{content.ceo.title[lang]}</span>
            <p>{content.ceo.firstname}</p>
            <p>{content.ceo.lastname}</p>
          </div>
        </div>
        <div css={[sRelative, { height: 'max-content' }]}>
          <GatsbyImage
            image={getImage(vice.childImageSharp)}
            alt={content.vice.firstname + ' ' + content.vice.lastname + alt}
            css={sImageVice}
          />
          <div css={[sText, { [s.md]: { alignItems: 'flex-end' } }]}>
            <span>{content.vice.title[lang]}</span>
            <p>{content.vice.firstname}</p>
            <p>{content.vice.lastname}</p>
          </div>
        </div>
      </div>
      <div css={sLabel}>
        <Label text={label.text[lang]} color={label.color} />
      </div>
    </section>
  )
}

const sText = {
  top: 0,
  left: 0,
  position: 'absolute',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  width: '100%',
  padding: '4rem',
  [s.sm_down]: {
    padding: '2rem',
  },

  span: {
    textTransform: 'uppercase',
    color: colors.brandLight,
    marginBottom: '1rem',
    fontWeight: 700,
    fontSize: '1rem',
    [s.sm_down]: {
      fontSize: '0.75rem',
      marginBottom: '0.5rem',
    },
  },

  p: {
    fontSize: '4rem',
    letterSpacing: '-0.05em',
    lineHeight: '1em',
    color: colors.white,
    fontWeight: 700,
    [s.sm_down]: {
      fontSize: '3rem',
    },
  },
}

const sRelative = {
  position: 'relative',
  top: 0,
  left: 0,
}

const sBanner = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  position: 'relative',
  top: 0,
  left: 0,
  zIndex: 1,
  borderRadius: '4rem',
  height: 'max-content',
  [s.sm_down]: {
    borderRadius: '1.5rem',
    gridTemplateColumns: '1fr',
  },
}

const sImageCeo = {
  borderRadius: '4rem 0 0 4rem',
  [s.sm_down]: {
    borderRadius: '2rem 2rem 0 0',
  },
}

const sImageVice = {
  ...sImageCeo,
  [s.md]: { transform: 'scaleX(-1)' },
  [s.sm_down]: {
    borderRadius: '0 0 2rem 2rem',
  },
}

const sLabel = {
  position: 'absolute',
  top: 4,
  left: '50%',
  transform: 'translate(-50%, -100%)',
  zIndex: 0,
}

export default BannerManagement
